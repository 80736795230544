import Logger from "./Logger";
import { v4 as uuid4 } from "uuid";

const L = Logger.getLogger("util/misc")

const scrollToOptions = { behavior: "auto" };

const scrollOffset = () => {
    const header = document.getElementsByClassName("mk-header-holder");
    return header.length > 0 ? header[0].offsetHeight : 0;
}

function getOffset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function randomString() {
    return Math.random().toString(36).substring(2);
}

export function scrollToElement(element, opts = scrollToOptions) {
    const pos = getOffset(element);
    opts.top = pos.top - scrollOffset();
    window.scrollTo(opts);
    L.debug(`Scrolling (offset: ${scrollOffset()}):`, "element:", element, "options:", opts);
}

export function scrollToElementId(elementId, options = scrollToOptions) {
    scrollToElement(document.getElementById(elementId), options);
}

//export function downloadFile(options = {data: null, type: null, filename: null}) {
export function downloadFile({data, type, filename, url} = {}) {
    let href = null;
    if (url) {
        href = url;
    } else if (data) {
        href = URL.createObjectURL(new Blob([data], { type }));
    } else {
        throw new Error("url or data parameter needed")
    }
    const link = document.createElement("a");
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getUuid() {
    return uuid4();
}
