import _ from "lodash";
import toast from "react-hot-toast";

export function check(condition, ...args) {
    if (!condition) {
        const e = new Error(args.join(" "));
        e.args = args;
        throw e;
    }
}

/* eslint no-console: 0 */
export function checkState(condition, errorMsg = "", ...args) {
    if (!condition) {
        if (errorMsg) toast.error(errorMsg);
        console.error(`ERROR: ${errorMsg}`, ...args);
        throw new Error();
    }
}

export function checkNotNull(value, errorMsg) {
    if (_.isUndefined(value) || value === null) {
        if (_.isUndefined(errorMsg)) {
            throw new Error(`value was ${value}`);
        } else {
            throw new Error(errorMsg);
        }
    } else {
        return value;
    }
}
