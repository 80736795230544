
export function supportsAsync() {
    try {
        // eslint-disable-next-line no-eval
        eval('async () => {}');
    } catch (e) {
        return false;
    }
    return true;
}

export function supportsWebSocket() {
    if ('WebSocket' in window && window.WebSocket.CLOSING === 2) {
        return true;
    } else {
        return false;
    }
}

export function detectBrowser() {
    let browser = "Unknown";
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) {
        browser = 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
        browser = 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") !== -1) {
        browser = 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") !== -1 ){
        browser = 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
        browser = 'IE';//crap
    } else {
        return 'Unknown';
    }
    return {
        browser,
        version: navigator.appVersion,
        platform: navigator.platform,
        cookies: navigator.cookieEnabled,
        language: navigator.language,
    }
}

export function windowDimensions() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    }
}

export function isTablet() {
    const ua = navigator.userAgent;
    return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
}

export function isPhone() {
    const ua = navigator.userAgent;
    return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);
}

export function isMobile() {
    if (typeof(navigator.userAgentData !== "undefined")) {
        if (typeof(navigator.userAgentData.mobile !== "undefined")) {
            return navigator.userAgentData.mobile;
        }
    } else {
        return isTablet() || isPhone();
    }
}
