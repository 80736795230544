import { check } from "./Preconditions";
import _ from "lodash";

function getTimeStamp() {
    return Date.now();
}

export default class Stopwatch {
    constructor(actionName, logger) {
        this._actionName = actionName;
        this._logger = logger;
        this._laps = [];
        this._watchStart = getTimeStamp();
    }

    newLap(name) {
        check(_.isUndefined(this._watchEnd), "watch already ended");
        this._laps.push({
            start: getTimeStamp(),
            name
        })
    }

    restart() {
        this._laps = [];
        delete this._watchEnd;
        this._watchStart = getTimeStamp();
    }

    stop() {
        if (_.isUndefined(this._watchEnd)) {
            this._watchEnd = getTimeStamp();
        }
    }

    printOverThreshold(threshold, fn) {
        this.stop();
        if (this._watchEnd - this._watchStart > threshold) {
            if (fn) {
                fn();
            }
            this.printTimes();
        }
    }

    isRunning() {
        return _.isUndefined(this._watchEnd);
    }

    getMessage() {
        if (this.isRunning()) {
            this.stop();
        }

        let message = `${this._actionName} took ${(this._watchEnd - this._watchStart).toFixed()} ms`;
        this._laps.forEach((lap, index) => {
            let lapEnd = this._watchEnd;
            if (index + 1 < this._laps.length) {
                lapEnd = this._laps[index + 1].start;
            }
            const lapTime = lapEnd - lap.start;
            message += `\n . ${_.padEnd(lap.name, 50)} ${_.padStart(lapTime.toFixed(), 10)} ms`;

        });

        return message;
    }

    printTimes() {
        this._logger.info(this.getMessage());
    }
}

