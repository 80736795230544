import Stopwatch from "./Stopwatch"
import _ from "lodash"

let baseTag = "";

export default class Logger {
    constructor(logFn) {
        this.error = logFn.error
        this.info = logFn.info
        this.debug = logFn.debug
        this.trace = logFn.trace


    }

    static setBaseTag(_baseTag) {
        baseTag = _baseTag;
    }

    static getLogger(tag) {
        const level = process.env.LOGLEVEL ? process.env.LOGLEVEL : "trace";
        return new Logger(require("webpack-log")({name: `${baseTag}${baseTag ? ":" : ""}${tag}`, level}));
        //return new Logger(require("debug")(`${baseTag}:${tag}`));
    }

    logException(err) {
        if (_.isString(err)) {
            err = {
                message: err,
                stack: new Error().stack.split("\n").slice(1).join("\n")
            }
        }

        /* use err.args (set by checkState) or an empty array with err.message (set normally) */
        const args = err.args || [err.message]

        /* remove errorMessage from stack */
        const stack = err.stack.split("\n").slice(1).join("\n").slice(1);
        this.error(...args, "\n\n", stack);
    }

    newStopwatch(name) {
        return new Stopwatch(name, this);
        // return FAKEWATCH;
    }
}
