import {toast} from "react-hot-toast";
import {getUuid} from "common/util/misc";
import Logger from "common/util/Logger";
import {supportsWebSocket, supportsAsync} from "./Compatibility";

const L = Logger.getLogger("WebSocketHandler")

export default class WebSocketHandler {
    socket = null;
    reconnect = true;
    user = null;
    eventListeners = {};
    url = null;
    heartbeatInterval = 10000;
    heartbeatTimer = null;
    chatSettings = {};
    chatPhrases = {};
    reconnectTimeout = 1000;

    constructor(url, heartbeat=10000, reconnectTimeout = 1000) {
        this.url = url;
        if (!supportsWebSocket()) {
            console.error("WebSocket is not supported by this browser.");
            throw Error("WebSocket is not supported by this browser.");
        }
        this.hasAsync = supportsAsync();
        this.heartbeatInterval = heartbeat;
        this.reconnectTimeout = reconnectTimeout;
    }

    readyState() {
        return this.socket ? this.socket.readyState : null
    }

    connect() {
        const url = this.url;
        if (this.readyState() in [WebSocket.OPEN, WebSocket.CONNECTING]) {
            L.debug("connection already established");
            return;
        }

        L.debug("new connection", url);
        this.socket = new WebSocket(url);

        const toastId = toast.loading("Verbinde mit Server");

        this.socket.onclose = (ev) => {
            document.dispatchEvent(new CustomEvent("ws-close", {detail: ev}));
            clearInterval(this.heartbeatTimer);
            L.debug("Connection closed", ev);
            if (this.reconnect) {
                toast.dismiss(toastId)
                const toastReconnectId = toast.loading("Verbinde mit Server");
                document.dispatchEvent(new CustomEvent("ws-reconnect"));

                setTimeout(() => { toast.dismiss(toastReconnectId); this.connect(url)}, this.reconnectTimeout);
            }
        };

        this.socket.onopen = (ev) => {
            L.debug("Connection established");
            toast.success("Verbindung zu Server hergestellt", {id: toastId});
            this.setHeartbeatInterval(this.heartbeatInterval);
            document.dispatchEvent(new CustomEvent("ws-open", {detail: ev}));
        };

        this.socket.onerror = (ev) => {
            document.dispatchEvent(new CustomEvent("ws-error", {detail: ev}));
            L.error("Connection error", ev);
        };

        this.socket.onmessage = (ev) => {
            const data = JSON.parse(ev.data);
            L.debug("received data:", data);
            this.handleMessage(data);

            document.dispatchEvent(new CustomEvent("ws-message", {detail: data}));
            document.dispatchEvent(new CustomEvent(`ws-message.${data.type}`, {detail: data}));
            if (data.type === "error") {
                document.dispatchEvent(new CustomEvent("ws-error", {detail: data}));
                document.dispatchEvent(new CustomEvent(`ws-error.${data.type}`, {detail: data}));
                if (["Not authenticated", "You were blocked"].includes(data.message)) {
                    this.reconnect = false;
                }
                L.error(data.message);
            }
            //this.handleMessage(data);
        };

    }

    setHeartbeatInterval(interval) {
        this.heartbeatInterval = interval;
        clearInterval(this.heartbeatTimer);
        this.heartbeatTimer = setInterval(this.heartbeat.bind(this), interval);
    }

    handleMessage(data) {
        if (data.type === 'chat.settings') {
            console.log("got settings", data.message)
            this.chatSettings = data.message.settings;
            this.chatPhrases = data.message.phrases;
        }
    }

    getPhrase(abbrev) {
        return this.chatPhrases[abbrev];
    }

    getPhrases() {
        return this.chatPhrases;
    }

    getSetting(short, def=null) {
        if (Object.keys(this.chatSettings).includes(short)) {
            return this.chatSettings[short].value;
        } else {
            return def
        }
    }

    getSettings() {
        return this.chatSettings;
    }



    close() {
        this.socket.close();
        this.socket = null;
    }

    send(data) {
        if (this.socket) {
            data.ticket = getUuid();
            L.debug("Sending message:", data);
            this.socket.send(JSON.stringify(data));
        }
    }

    addWsEventListener(context, event, func) {
        if (!this.eventListeners[context]) {
            this.eventListeners[context] = []
        }
        this.eventListeners[context].push({
            event,
            func
        });
        L.debug(`Adding Event Listener for context: ${context} event: ${event} func: ${func.name}`)
        document.addEventListener(`ws-message.${event}`, func);
    }

    removeWsEventListener(context, event, func=null) {
        if (!this.eventListeners[context]) {
            return;
        }
        let listeners = this.eventListeners[context]
        if (func) {
            listeners = listeners.filter(l => l.func === func && l.event === event);
        }
        listeners.forEach(l => {
            L.debug(`Removing Event Listener for context: ${context}, event: ${l.event} func: ${l.func.name}`)
            document.removeEventListener(`ws-message.${l.event}`, l.func)
        });
        this.eventListeners[context] = this.eventListeners[context].filter(l => !(l in listeners));
    }

    removeWsEvents(context) {
        this.eventListeners[context].forEach(l => {
            L.debug(`Removing Event Listener for context: ${context}, event: ${l.event}, func: ${l.func.name}`)
            document.removeEventListener(`ws-message.${l.event}`, l.func)
        });
        this.eventListeners[context] = []
    }

    heartbeat() {
        L.debug("sending heartbeat");
        if (this.socket && this.readyState() === this.socket.OPEN) {
            this.send({type: "heartbeat"})
        }
    }
}

document.WebSocketHandler = WebSocketHandler;
